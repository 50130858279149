import React, { useState } from 'react';
import SectionHeader from '../../components/sections/partials/SectionHeader';

import GenericSection from '../../components/sections/GenericSection';

import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import Accordion from '../../components/elements/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';

import selectOptions from "../../utils/selectOption.json";
import controlList from "../../utils/controls.json";
import Select from "react-select";
import { faBridgeLock } from '@fortawesome/free-solid-svg-icons';

class RentPropCalc extends React.Component {

    state = {
      demoModalActive: false
    }
  
    openModal = (e) => {
      e.preventDefault();
      this.setState({ demoModalActive: true });
    }
  
    closeModal = (e) => {
      e.preventDefault();
      this.setState({ demoModalActive: false });
    }
  
    constructor(props) {
      super(props);
      this.state = {
        result: 0,
        downpaymentAmount: 0,
        closingAmount: 0,
        monthlyMortPayment: 0,
        monthlyCashFlow: 0,
        roi: 0,


        details: {
          numberOfMonths: "",
          interestRate: "",
          monthlyPaymentAmount: "",
          loanAmount: "",
          termLength: ""
        },
        selectedOption: "Residential",
        error: false,
        resultLabel: "Mortgage"
      };
      this.onSubmitHandle = this.onSubmitHandle.bind(this);
      this.onChangeHandle = this.onChangeHandle.bind(this);
      this.clearAll = this.clearAll.bind(this);
    }
  
    onSubmitHandle(e) {
      e.preventDefault();
      this.calculateAndSetResults();
    }
  
    clearAll() {
      this.setState({
        error: false,
        details: {
          numberOfMonths: "",
          interestRate: "",
          monthlyPaymentAmount: "",
          loanAmount: "",
          termLength: ""
        },
        result: 0,
        downpayment: 0,
        closingAmount: 0,
        monthlyCashFlow: 0,
      });
    }
  
    setError() {
      this.setState({ error: true });
    }
  
    setResult(result, downpaymentAmount, closingAmount, monthlyMortPayment, monthlyCashFlow, roi) {
      this.setState({ result });
      this.setState({ downpaymentAmount });
      this.setState({ closingAmount });
      this.setState({ monthlyMortPayment });
      this.setState({ monthlyCashFlow });
      this.setState({ roi });
    }

  
    calculateAndSetResults() {
      /* eslint no-eval: 0 */
      let result = 0;
      let loanAmount;
      let purchasePrice;
      let downpaymentPercent;
      let downpayment;
      let closingAmount;
      let monthlyMortPayment;
      let potentialRent;
      let monthlyStrata;
      let monthlyTaxes;
      let monthlyInsurance;
      let maintenance;
      let vacancyRate;
      let propMan;
      let utilities;
      let repairLoan;
      let monthlyRepairPayment;
      let monthlyCashFlow;
      let roi;


      let interestRate;
      let numberOfMonths;
      let monthlyPaymentAmount;
      try{
        switch (this.state.selectedOption) {
            case "Residential":
            if (this.state.details.purchasePrice.trim() !== "") {
                purchasePrice = eval(this.state.details.purchasePrice);
                result = purchasePrice;
                downpaymentPercent = eval(this.state.details.downpayment);
                downpayment = purchasePrice*downpaymentPercent*0.01;
                closingAmount= downpayment + (purchasePrice*0.045);
                loanAmount = purchasePrice - downpayment;
                interestRate = eval(this.state.details.interestRate / 1200);
                numberOfMonths = eval(this.state.details.mortAmor * 12);

                monthlyMortPayment =
                    eval(loanAmount * interestRate) /
                    (1 - Math.pow(1 + interestRate, numberOfMonths * -1)).toFixed(2);
                repairLoan = eval(this.state.details.repairs)
                monthlyRepairPayment =
                    eval(repairLoan * interestRate) /
                    (1 - Math.pow(1 + interestRate, -24)).toFixed(2);

                potentialRent = eval(this.state.details.potentialRent);
                monthlyStrata = eval(this.state.details.monthlyStrata);
                monthlyTaxes = eval(this.state.details.propTaxes / 12);
                monthlyInsurance = eval(this.state.details.insurance);
                maintenance = (potentialRent * 0.05); 
                vacancyRate = (potentialRent * 0.05); 
                propMan = (potentialRent * 0.12);
                utilities = eval(this.state.details.utilities);

                monthlyCashFlow = (potentialRent- monthlyMortPayment - monthlyStrata - monthlyTaxes - maintenance - vacancyRate - propMan - utilities - monthlyRepairPayment);

                roi= ((monthlyCashFlow*12)/closingAmount)*100;


            } else {
                this.setError();
            }
            break;
    
            case "Commercial":
                if (this.state.details.purchasePrice.trim() !== "") {
                    purchasePrice = eval(this.state.details.purchasePrice);
                    result = purchasePrice;
                    downpaymentPercent = eval(this.state.details.downpayment);
                    downpayment = purchasePrice*downpaymentPercent*0.01;
                    closingAmount= downpayment + (purchasePrice*0.045);
                    loanAmount = purchasePrice - downpayment;
                    interestRate = eval(this.state.details.interestRate / 1200);
                    numberOfMonths = eval(this.state.details.mortAmor * 12);

                    monthlyMortPayment =
                        eval(loanAmount * interestRate) /
                        (1 - Math.pow(1 + interestRate, numberOfMonths * -1)).toFixed(2);
                    repairLoan = eval(this.state.details.repairs)
                    monthlyRepairPayment =
                        eval(repairLoan * interestRate) /
                        (1 - Math.pow(1 + interestRate, -24)).toFixed(2);

                    potentialRent = eval(this.state.details.potentialRent);
                    monthlyStrata = eval(this.state.details.monthlyStrata);
                    monthlyTaxes = eval(this.state.details.propTaxes / 12);
                    monthlyInsurance = eval(this.state.details.insurance);
                    maintenance = (potentialRent * 0.05); 
                    vacancyRate = (potentialRent * 0.05); 
                    propMan = (potentialRent * 0.12);
                    utilities = eval(this.state.details.utilities);

                    monthlyCashFlow = (potentialRent- monthlyMortPayment - monthlyStrata - monthlyTaxes - maintenance - vacancyRate - propMan - utilities - monthlyRepairPayment);

                    roi= ((monthlyCashFlow*12)/closingAmount)*100;
                } else {
                    this.setError();
                }
            break;

            default:
            break;
        }
        this.setResult(result, downpayment, closingAmount, monthlyMortPayment, monthlyCashFlow, roi);

      }
      catch{
        this.setError();
      }
  
    }
  
    onChangeHandle({ value, resultLabel }) {
      this.clearAll();
      this.setState({ selectedOption: value, resultLabel: resultLabel });
    }
  
    onInputChange(e, type) {
      e.persist();
      this.setState((state) => {
        state.details[type] =
          e.target.value.trim() >= 0 ? e.target.value.trim() : "";
        state.result = 0;
        state.error = false;
        return state;
      });
    }
  
    Error() {
      return (
        <div
          className="errorWrap align-center"
          style={{
            display: (this.state.error && "flex") || "none",
            color: "red"
          }}
        >
          <em>Please insert vaild inputs!</em>
        </div>
      );
    }
  
    Results() {
      return (
        <div
          className="resultBlock"
          style={{ display: (this.state.result && "block") || "none" }}
        >
          <span className="mt-0 mb-32">
            Purchase Price: {this.state.result}
          </span>
          <span className="mt-0 mb-32">
            <br></br>
            Downpayment: {this.state.downpaymentAmount}
          </span>
          <span className="mt-0 mb-32">
            <br></br>
            Closing Amount: {this.state.closingAmount}
          </span>
          <span className="mt-0 mb-32">
            <br></br>
            Monthly Mortgage Payment: {(this.state.monthlyMortPayment).toFixed(2)}
          </span>
          <span className="mt-0 mb-32">
            <br></br>
            Monthly Cash Flow: {(this.state.monthlyCashFlow).toFixed(2)}
          </span>
          <span className="mt-0 mb-32">
            <br></br>
            Cash on cash ROI: {(this.state.roi).toFixed(2)}
          </span>
        </div>
      );
    }
  
    renderInputControls() {
      const list = controlList[this.state.selectedOption];
  
      return (
        <React.Fragment>
          {list.map((item) => {
            return (
              <div className="input-control" key={item.event} style={{ flex: '1 1 45%', margin: '10px' }}>
                <label>{item.label}</label>
                <Input
                  type="number"
                  onChange={(e) => this.onInputChange(e, item.event)}
                  value={this.state.details[item.event]}
                />
              </div>
            );
          })}
        </React.Fragment>
      );
    }
  
    render() {
  
      const genericSection01Header = {
        title: 'Newsletter modal is a component commonly used'
      }    
  
      const genericSection02Header = {
        title: 'Button is a component commonly used'
      }
  
      const genericSection03Header = {
        title: 'Input form is a component commonly used'
      }
  
      const genericSection04Header = {
        title: 'Frequently asked questions'
      }    
  
      return (
        <React.Fragment>
  
          <GenericSection className="has-bg-color-cut illustration-section-02"  hasBgColor invertColor topDivider>
            <div className="container-xs">
              <h2 className="mt-0">Rental Property Calculator</h2>
              <p>
                How profitable is your deal?
              </p>
              <p>
                Enter your deals details below to find the details
                
                </p>
  
              <div class="box">
                          <span class="image featured"><img src="images\pexels-kampus-8730060.jpg" alt="" /></span>
                      </div>
  
            <div className="container-xs">
              <div className="wrapperInner">
                <form style={{ display: 'flex', flexWrap: 'wrap' }} onSubmit={(e) => this.onSubmitHandle(e)}>
                  <div className="mb-24" style={{ width: '100%', margin: '10px' }}>
                    <label>Please Select Deal Type</label>
                    <Select
                      options={selectOptions}
                      onChange={(obj) => this.onChangeHandle(obj)}
                      isSearchable={false}
                      isClearable={false}
                      defaultValue={selectOptions[0]}
                      style={{ flex: '100%', margin: '10px' }}
                    />
                    <hr></hr>
                  </div>
                    {this.renderInputControls()}
                  <div className="mb-24" style={{ width: '100%', margin: '10px' }}>
                    <Button
                      color="primary"
                      type="submit"
                      className="submitBtn "
                      data-event_tag="CalculateBtnClick"
                      data-event_action="UserClick">
                      Calculate
                    </Button>
                    <Button
                      type="button"
                      className="cancelBtn "
                      data-event_tag="ClearAllClick"
                      data-event_action="UserClick"
                      onClick={this.clearAll}
                    >
                      Clear All
                    </Button>
                  </div>
                </form>
                {this.Error()}
                {this.Results()}
              </div>
            </div>
          </div>
          </GenericSection>              
  
  
        
  
          <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
                <AccordionItem title="What is the Rental Property Profitability Calculator?">
                The Rental Property Profitability Calculator helps you estimate how much profit you can expect from a rental property by considering factors like purchase price, rental income, expenses, and more.
                </AccordionItem>
                <AccordionItem title="How do I calculate my rental income?">
                Your rental income is the total amount you expect to receive from tenants, typically on a monthly basis. Input the expected rent you’ll charge, and the calculator will help you determine annual income and profitability.
                </AccordionItem>
                <AccordionItem title="What expenses should I include in the calculator?">
                Include expenses such as mortgage payments, property taxes, insurance, maintenance, management fees, utilities (if applicable), and any other recurring costs associated with owning and maintaining the rental property.
                </AccordionItem>
                <AccordionItem title="What is the difference between gross and net income?">
                Gross income refers to the total rental income before expenses. Net income is what remains after all expenses, like mortgage payments, repairs, and management fees, are subtracted from the gross income. The calculator helps you calculate both.
                </AccordionItem>
                <AccordionItem title="How do I account for property appreciation?">
                Property appreciation refers to the increase in property value over time. The calculator doesn’t directly account for future appreciation, but you can factor it into your long-term investment strategy. Keep in mind that appreciation is speculative and varies by market.
                </AccordionItem>
                <AccordionItem title="How does the occupancy rate affect profitability?">
                The occupancy rate is the percentage of time your property is rented out. A higher occupancy rate increases your rental income, while a lower rate reduces it. The calculator lets you adjust the occupancy rate to see how it affects your profits.
                </AccordionItem>
                <AccordionItem title="Can this calculator help me decide if I should buy a property?">
                Yes! By entering key financial details, the calculator will show your potential returns, allowing you to compare properties and determine if an investment is likely to be profitable.
                </AccordionItem>
            </Accordion>
            </div>
          </GenericSection>         
        </React.Fragment>
      );
    }
  }
  
  // inline style
  const formStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  const modalFormStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  export default RentPropCalc;