import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
import RentPropCalc from './views/RentPropCalc';
import MortApprove from './views/mortApproveCalc';
import MortCost from './views/MortCostCalc'
import RehabCostCalc from './views/RehabCostCalc'
import BRRRRCalc from './views/BrrrrCalc'
import RentEstimator from './views/RentEstimator';
import AppraisalEstimator from './views/appraisal';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded');
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch> {/* Use Switch to wrap all Route components */}
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutDefault} />
            <AppRoute exact path="/RentalPropertyCalculator" component={RentPropCalc} layout={LayoutDefault} />
            <AppRoute exact path="/MortgageApprovalCalculator" component={MortApprove} layout={LayoutDefault} />
            <AppRoute exact path="/MortgageCostCalculator" component={MortCost} layout={LayoutDefault} />
            <AppRoute exact path="/RehabCostCalculator" component={RehabCostCalc} layout={LayoutDefault} />
            <AppRoute exact path="/BRRRRCalculator" component={BRRRRCalc} layout={LayoutDefault} />
            <AppRoute exact path="/RentEstimator" component={RentEstimator} layout={LayoutDefault} />
            <AppRoute exact path="/AppraisalEstimator" component={AppraisalEstimator} layout={LayoutDefault} />
          </Switch>
        )} />
    );
  }
}

const WrappedApp = () => (
  <BrowserRouter> {/* Wrap the whole app in BrowserRouter */}
    <App />
  </BrowserRouter>
);

export default WrappedApp;
