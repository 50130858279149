import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import RehabCostCalc from '../components/sections/rehabCostCalc';
import RehabCostEstimator from '../components/sections/rehabCostEstimator'
import RehabCostCalcFAQ from '../components/sections/faq/rehabCostCalcFAQ'
import Cta from '../components/sections/Cta';

class RentPropCalc extends React.Component {
  render() {
    return (
      <React.Fragment>
        <RehabCostCalc hasBgColor invertColor />
        <RehabCostEstimator hasBgColor invertColor />
        <RehabCostCalcFAQ hasBgColor invertColor />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default RentPropCalc;