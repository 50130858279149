import React, { useState } from 'react';
import SectionHeader from '../../../components/sections/partials/SectionHeader';

import GenericSection from '../../../components/sections/GenericSection';
import Accordion from '../../../components/elements/Accordion';
import AccordionItem from '../../../components/elements/AccordionItem';


class RehabCostCalcFAQ extends React.Component {



    state = {
      demoModalActive: false,
    }
  

    constructor(props) {
        super(props);
      }


 

  
 
  
    render() {

    
      const genericSection04Header = {
        title: 'Frequently asked questions'
      }    
  
      return (
        <React.Fragment>
                 
  
          <GenericSection topDivider>
            <div className="container-xs">
              <SectionHeader data={genericSection04Header} className="center-content" />
              <Accordion>
                <AccordionItem title="What is the Rehab Expense Calculator?">
                  The Rehab Expense Calculator helps you estimate the total costs associated with renovating a property. It considers various factors like materials, labor, permits, and unexpected expenses to give you a comprehensive view of your potential rehab budget.
                </AccordionItem>
                <AccordionItem title="How do I input my rehab expenses?">
                  You can input your expected expenses in different categories such as materials, labor, and permits. The calculator will aggregate these inputs to provide you with a total estimated cost for your rehab project.
                </AccordionItem>
                <AccordionItem title="What types of expenses should I include in the calculator?">
                  Include expenses such as materials (lumber, paint, fixtures), labor costs (contractors, subcontractors), permits, inspection fees, and any unexpected costs that may arise during the renovation process.
                </AccordionItem>
                <AccordionItem title="How does the calculator handle unexpected expenses?">
                  The calculator allows you to set a percentage for unexpected expenses, which is added to your total costs. This feature helps you plan for contingencies that may arise during your rehab project.
                </AccordionItem>
                <AccordionItem title="Can this calculator help me determine if a property is worth buying?">
                  Absolutely! By inputting your estimated rehab costs alongside the purchase price, you can assess whether the total investment aligns with your budget and potential returns, helping you make informed purchasing decisions.
                </AccordionItem>
                <AccordionItem title="What is the difference between hard and soft costs?">
                  Hard costs are direct construction expenses, such as materials and labor. Soft costs are indirect expenses, including permits, fees, and financing costs. The calculator helps you differentiate and account for both in your estimates.
                </AccordionItem>
                <AccordionItem title="How can I use the results from the calculator?">
                  The results will provide a detailed breakdown of your estimated rehab costs, allowing you to adjust your budget, plan your financing, and prioritize renovations based on your financial goals and available resources.
                </AccordionItem>
              </Accordion>
            </div>
          </GenericSection>      
        </React.Fragment>
      );
    }
  }
  
  // inline style
  const formStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  const modalFormStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  export default RehabCostCalcFAQ;