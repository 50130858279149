import React, { useRef } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import emailjs from '@emailjs/browser';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const sectionHeader = {
  title: 'Welcome. Sign up for updates',
};

export const SignUpForm = () => {

  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_4wcehcx', 'template_xk6li3r', form.current, {
        publicKey: 'lOnpKXniQaGy2_3ux',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  const class1 = 'signin section';
  const class2 = 'has-top-divider';

  const combinedClasses = classNames(class1, class2);

  const outerClasses = classNames(
    'signin section',
    'has-top-divider',
    'has-bottom-divider',
    'has-bg-color',
    'invert-color',
  );

  const innerClasses = classNames(
    'signin-inner section-inner',
    'has-top-divider',
    'has-bottom-divider',
    'has-bg-color',
    'invert-color',
  );



  const formStyle = {
    maxWidth: '420px',
    margin: '0 auto'
  }

  return (

    <section className = {outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form ref={form} onSubmit={sendEmail} style={formStyle}>
                    <fieldset>
                      <div className="mb-12">
                        <Input
                          label="Full name"
                          name="name"
                          placeholder="Full name" 
                          labelHidden
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="email"
                          label="Email"
                          name="email"
                          placeholder="Email"
                          labelHidden
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="comment"
                          name="comment"
                          label="comment"
                          placeholder="Comment"
                          labelHidden/>
                      </div>
                      <div className="mt-24 mb-32">
                        <Button color="primary" wide>Sign up</Button>
                      </div>
                    </fieldset>
                  </form>
                  <div className="signin-bottom has-top-divider">
                    <div className="pt-32 text-xs center-content text-color-low">
                      Already have an account? <Link to="/login/" className="func-link">Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

  );
};