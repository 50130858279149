import React, { useState, useEffect } from 'react';
import axios from 'axios';

import GenericSection from '../../components/sections/GenericSection';

const RehabEstimatorSection = () => {

  const [input, setInput] = useState('');
  const [formInput, setFormInput] = useState('');
  const [response, setResponse] = useState('');
  const appApiKey = process.env.REACT_APP_OPENAI_API_KEY;

  const [formData, setFormData] = useState({
    type: '',
    city: '',
    state: '',
    country: 'USA',
    unit: 'SQFT',
    amount: '',
    
  });

  const [submittedData, setSubmittedData] = useState(null);

  const usStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida',
    'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const canadianProvinces = [
    'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia',
    'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'
  ];

  const subcategoryNames = [
      'Roofing', 'Siding', 'Windows', 'Doors', 'Landscaping',
      'Driveway', 'Garage', 'Fencing', 'Painting', 'Lighting', 'Deck/Patio',
      'Flooring', 'Drywall', 'Plumbing', 'Electrical', 'HVAC',
      'Insulation', 'Cabinetry', 'Countertops', 'Appliances', 'Painting', 'Lighting'
  ];

  const stateOptions = formData.country === 'USA' ? usStates : canadianProvinces;

  useEffect(() => {
    const sendMessage = async () => {
      try {
        const apiUrl = 'https://api.openai.com/v1/chat/completions'; // Update with the correct API endpoint
        const apiKey =  appApiKey
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        };

        const requestBody = {
          "model": "gpt-3.5-turbo",
          "messages": [{ role: 'user', content: formInput }],
        };

        const { data } = await axios.post(apiUrl, requestBody, { headers });

        setResponse(data.choices[0].message.content);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };

    if (formInput) {
      sendMessage(); // Call the async function when formInput changes
      console.log(formInput);
    }
  }, [formInput]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedData(formData); // Save the submitted data
    setFormInput("Reply with only the dollar value. What would the total cost be for a " + (formData.amount) + ' ' + (formData.unit) + ' ' + (formData.type) + " rehabilitation, in " + (formData.city) + ", " + (formData.state) + ", " + (formData.country) + "?");
  };




  return (
    <React.Fragment>
       
        <GenericSection className="has-bg-color-cut illustration-section-02"  hasBgColor invertColor topDivider>

        <h2 className="mt-0">Rehab Estimator</h2>
        <p>
          Enter your details below for a quick estimation on the price of a project.
        </p>

        <hr></hr>

        <div class="box">
            <span class="image featured"><img src="images\pexels-kampus-8730060.jpg" alt="" /></span>
        </div>

          <form onSubmit={handleSubmit}>

          <div>
              <label htmlFor="type">Estimate type:</label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Type</option>
                {subcategoryNames.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>


            <div>
              <label htmlFor="city">City:</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="country">Country:</label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="USA">USA</option>
                <option value="Canada">Canada</option>
              </select>
            </div>

            <div>
              <label htmlFor="state">State/Province:</label>
              <select
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
              >
                <option value="">Select State/Province</option>
                {stateOptions.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="unit">Units:</label>
              <select
                id="unit"
                name="unit"
                value={formData.unit}
                onChange={handleChange}
              >
                <option value="sqft">SQFT</option>
                <option value="units">units</option>
              </select>
            </div>

            <div>
              <label htmlFor="amount">Amount:</label>
              <input
                type="text"
                id="amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
              />
            </div>

            <button type="submit">Submit</button>
          </form>

          <div>
            <hr></hr>
            <h2 className="mt-0">{response} </h2>
          </div>

        </GenericSection>
    </React.Fragment>
  );
};

export default RehabEstimatorSection;