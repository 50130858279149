import React, { useState } from 'react';
import SectionHeader from '../../components/sections/partials/SectionHeader';

import GenericSection from '../../components/sections/GenericSection';

import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import Accordion from '../../components/elements/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';

import selectOptions from "../../utils/selectOption.json";
import controlList from "../../utils/controls.json";
import Select from "react-select";
import { faBridgeLock } from '@fortawesome/free-solid-svg-icons';

class RehabCostCalc extends React.Component {

  static subcategoryNames = {
    exterior: [
      'Roofing', 'Siding', 'Windows', 'Doors', 'Landscaping',
      'Driveway', 'Garage', 'Fencing', 'Painting', 'Lighting', 'Deck/Patio'
    ],
    interior: [
      'Flooring', 'Drywall', 'Plumbing', 'Electrical', 'HVAC',
      'Insulation', 'Cabinetry', 'Countertops', 'Appliances', 'Painting', 'Lighting'
    ]
  };

    state = {
      demoModalActive: false,
      category: '',
      subcategory: '',
      subcategoryName: '',
    }
  

    constructor(props) {
        super(props);
        
        this.state = {
          category: '',
          subcategory: '',
          labelName: '',
          // Exterior expense inputs (11 subcategories, 4 fields each)
          exteriorSub1Field1: 0,
          exteriorSub1Field2: 0,
          exteriorSub1Field3: 0,
          exteriorSub1Field4: 0,
          exteriorSub2Field1: 0,
          exteriorSub2Field2: 0,
          exteriorSub2Field3: 0,
          exteriorSub2Field4: 0,
          exteriorSub3Field1: 0,
          exteriorSub3Field2: 0,
          exteriorSub3Field3: 0,
          exteriorSub3Field4: 0,
          exteriorSub4Field1: 0,
          exteriorSub4Field2: 0,
          exteriorSub4Field3: 0,
          exteriorSub4Field4: 0,
          exteriorSub5Field1: 0,
          exteriorSub5Field2: 0,
          exteriorSub5Field3: 0,
          exteriorSub5Field4: 0,
          exteriorSub6Field1: 0,
          exteriorSub6Field2: 0,
          exteriorSub6Field3: 0,
          exteriorSub6Field4: 0,
          exteriorSub7Field1: 0,
          exteriorSub7Field2: 0,
          exteriorSub7Field3: 0,
          exteriorSub7Field4: 0,
          exteriorSub8Field1: 0,
          exteriorSub8Field2: 0,
          exteriorSub8Field3: 0,
          exteriorSub8Field4: 0,
          exteriorSub9Field1: 0,
          exteriorSub9Field2: 0,
          exteriorSub9Field3: 0,
          exteriorSub9Field4: 0,
          exteriorSub10Field1: 0,
          exteriorSub10Field2: 0,
          exteriorSub10Field3: 0,
          exteriorSub10Field4: 0,
          exteriorSub11Field1: 0,
          exteriorSub11Field2: 0,
          exteriorSub11Field3: 0,
          exteriorSub11Field4: 0,
          // ... repeat for 11 subcategories of Exterior
    
          // Interior expense inputs (11 subcategories, 4 fields each)
          interiorSub1Field1: 0,
          interiorSub1Field2: 0,
          interiorSub1Field3: 0,
          interiorSub1Field4: 0,
          interiorSub2Field1: 0,
          interiorSub2Field2: 0,
          interiorSub2Field3: 0,
          interiorSub2Field4: 0,
          interiorSub3Field1: 0,
          interiorSub3Field2: 0,
          interiorSub3Field3: 0,
          interiorSub3Field4: 0,
          interiorSub4Field1: 0,
          interiorSub4Field2: 0,
          interiorSub4Field3: 0,
          interiorSub4Field4: 0,
          interiorSub5Field1: 0,
          interiorSub5Field2: 0,
          interiorSub5Field3: 0,
          interiorSub5Field4: 0,
          interiorSub6Field1: 0,
          interiorSub6Field2: 0,
          interiorSub6Field3: 0,
          interiorSub6Field4: 0,
          interiorSub7Field1: 0,
          interiorSub7Field2: 0,
          interiorSub7Field3: 0,
          interiorSub7Field4: 0,
          interiorSub8Field1: 0,
          interiorSub8Field2: 0,
          interiorSub8Field3: 0,
          interiorSub8Field4: 0,
          interiorSub9Field1: 0,
          interiorSub9Field2: 0,
          interiorSub9Field3: 0,
          interiorSub9Field4: 0,
          interiorSub10Field1: 0,
          interiorSub10Field2: 0,
          interiorSub10Field3: 0,
          interiorSub10Field4: 0,
          interiorSub11Field1: 0,
          interiorSub11Field2: 0,
          interiorSub11Field3: 0,
          interiorSub11Field4: 0,
          // ... repeat for 11 subcategories of Interior
    
          total: 0
        };
      }

      
      handleCategoryChange = (e) => {
        this.setState({ category: e.target.value, subcategory: '', subcategoryName: '' });
      };
    
      handleSubcategoryChange = (e) => {
        const subcategoryIndex = parseInt(e.target.value, 10) - 1; // Subtract 1 to get the correct index
        const subcategoryName =
          this.state.category === 'Exterior'
            ? RehabCostCalc.subcategoryNames.exterior[subcategoryIndex]
            : RehabCostCalc.subcategoryNames.interior[subcategoryIndex];
        this.setState({ subcategory: e.target.value, subcategoryName });
      };
    
      handleInputChange = (e, field) => {
        const value = e.target.value === '' ? 0 : parseFloat(e.target.value);
        this.setState({ [field]: value });
      };
    
      calculateTotal = () => {
        let total = 0;
        // Sum all fields
        for (let i = 1; i <= 11; i++) {
          for (let j = 1; j <= 4; j++) {
            total += this.state[`exteriorSub${i}Field${j}`] + this.state[`interiorSub${i}Field${j}`];
          }
        }
    
        this.setState({ total });
      };
    
      handleSubmit = (e) => {
        e.preventDefault();
        this.calculateTotal();
      };
    
      renderInputs = (categoryType, subcategory) => {
        const fields = [];
        
        for (let i = 1; i <= 4; i++) {
          const fieldKey = `${categoryType}Sub${subcategory}Field${i}`;
          if(i==1){
            this.state.labelName='Tear Down'
          }
          if(i==2){
            this.state.labelName='Materials'
          }
          if(i==3){
            this.state.labelName='Labor'
          }
          if(i==4){
            this.state.labelName='Other'
          }
          fields.push(
            <div key={fieldKey}>
              <label>{this.state.labelName + ": "} </label>
              <input
                type="number"
                value={this.state[fieldKey]}
                onChange={(e) => this.handleInputChange(e, fieldKey)}
              />
              <br />
            </div>
          );
        }
        return fields;
      };
 

  
 
  
    render() {

    
      const genericSection04Header = {
        title: 'Frequently asked questions'
      }    

      const { category, subcategory,  subcategoryName, total } = this.state;
  
      return (
        <React.Fragment>
  
          <GenericSection className="has-bg-color-cut illustration-section-02"  hasBgColor invertColor topDivider>
            <div className="container-xs">
              <h2 className="mt-0">Rehab Cost Calculator</h2>
              <p>
                How much is the total Rehab going to cost?
              </p>
              <p>
                Enter your deals details below to add it all up. Use our estimator tool to help when you need it.
              </p>

              <div>
                <form onSubmit={this.handleSubmit}>
                <h1>Expense Total Calculator</h1>

                {/* Category Selection */}
                <div>
                    <label>Select Category:</label>
                    <select value={category} onChange={this.handleCategoryChange}>
                    <option value="">--Select Category--</option>
                    <option value="Exterior">Exterior Expenses</option>
                    <option value="Interior">Interior Expenses</option>
                    </select>
                </div>

                {/* Subcategory Selection */}
                {category && (
                  <div>
                    <label>Select Subcategory:</label>
                    <select value={subcategory} onChange={this.handleSubcategoryChange}>
                      <option value="">--Select Subcategory--</option>
                      {RehabCostCalc.subcategoryNames[category.toLowerCase()].map((name, idx) => (
                        <option key={idx} value={idx + 1}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Inputs for the selected subcategory */}
                {subcategory && (
                  <div>
                    <h3>
                      {category} Expenses - {this.state.subcategoryName}
                    </h3>
                    {this.renderInputs(category.toLowerCase(), subcategory)}
                  </div>
                )}

                {/* Submit Button */}
                <button type="submit">Calculate Total</button>
                </form>

                {/* Display Total */}
                {total !== 0 && (
                <div>
                    <h2>Total: {total}</h2>
                </div>
                )}
            </div>
  
          </div>
          </GenericSection>                  
        </React.Fragment>
      );
    }
  }
  
  // inline style
  const formStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  const modalFormStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  export default RehabCostCalc;