import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import '@fortawesome/fontawesome-free/css/all.css';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <Button tag="a" color="dark" href="/RentalPropertyCalculator">
                      Rental Property Calculator
                      </Button>
                    </h4>
                    <p className="m-0 text-sm">
                      Analyze cash flow, ROI, and key metrics for rental properties to ensure you're making sound investment decisions.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <Button tag="a" color="dark" href="/RehabCostCalculator">
                      Rehab Cost Calculator</Button>
                    </h4>
                    <p className="m-0 text-sm">
                      Estimate the cost of property renovations with accuracy, helping you stay within budget and maximize returns.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <Button tag="a" color="dark" href="/RentEstimator">
                        Rent Estimator</Button>
                    </h4>
                    <p className="m-0 text-sm">
                      Determine potential rental income based on market data and property features to forecast your investment performance.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <Button tag="a" color="dark" href="/MortgageCostCalculator">
                      Mortgage Cost Calculator</Button>
                    </h4>
                    <p className="m-0 text-sm">
                      Find out how much your mortgage is going to cost you monthly. Or, the total loan amount you can obtain with a set monthly payment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <Button tag="a" color="dark" href="/MortgageApprovalCalculator">
                      Mortgage Approval Calculator</Button>
                    </h4>
                    <p className="m-0 text-sm">
                      Accurately obtain an estimate on the mortgage amount which you could be approved for.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="500">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <Button tag="a" color="dark" href="/AppraisalEstimator">
                      Appraisal Estimator</Button>
                    </h4>
                    <p className="m-0 text-sm">
                      Get a quick estimated appraisal of the potential purchase/sale price of a house, town-home, or apartment.
                    </p>
                  </div>
                </div>
              </div>              

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
