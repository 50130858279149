import React, { useState } from 'react';
import SectionHeader from '../../components/sections/partials/SectionHeader';

import GenericSection from '../../components/sections/GenericSection';

import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import Accordion from '../../components/elements/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';



class BRRRRCalcSection extends React.Component {

    constructor(props) {
        super(props);
        // Initialize state to track current step and form inputs
        this.state = {
          step: 1,
          formData: {
            input1: '', input2: '', input3: '', input4: '', input5: '', input6: '', input7: '',
            input8: '', input9: '', input10: '', input11: '', input12: '', input13: '', input14: ''
          }
        };
      }
    
      // Function to handle input changes
      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [name]: value
          }
        }));
      };
    
      // Move to the next step
      nextStep = () => {
        this.setState((prevState) => ({
          step: prevState.step + 1
        }));
      };
    
      // Go back to the previous step
      prevStep = () => {
        this.setState((prevState) => ({
          step: prevState.step - 1
        }));
      };
    
      // Handle form submission, moves to step 3
      handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ step: 3 });
      };
  

  
    render() {

        const { step, formData } = this.state;
  
  
        const genericSection04Header = {
        title: 'Frequently asked questions'
        }    
  
      return (
        <React.Fragment>
  
          <GenericSection className="has-bg-color-cut illustration-section-02"  hasBgColor invertColor topDivider>
            <div className="container-xs">
              <h2 className="mt-0">Rental Property Calculator</h2>
              <p>
                How profitable is your deal?
              </p>
              <p>
                Enter your deals details below to find the details
              </p>
              <div class="box">
                <span class="image featured"><img src="images\pexels-kampus-8730060.jpg" alt="" /></span>
            </div>
  
            <div className="container-xs">
            <div>
                <h2>Multi-Step Form</h2>
                {step === 1 && (
                    <div>
                    <h3>Step 1</h3>
                    <form>
                        <input type="text" name="input1" placeholder="Input 1" value={formData.input1} onChange={this.handleChange} /><br />
                        <input type="text" name="input2" placeholder="Input 2" value={formData.input2} onChange={this.handleChange} /><br />
                        <input type="text" name="input3" placeholder="Input 3" value={formData.input3} onChange={this.handleChange} /><br />
                        <input type="text" name="input4" placeholder="Input 4" value={formData.input4} onChange={this.handleChange} /><br />
                        <input type="text" name="input5" placeholder="Input 5" value={formData.input5} onChange={this.handleChange} /><br />
                        <input type="text" name="input6" placeholder="Input 6" value={formData.input6} onChange={this.handleChange} /><br />
                        <input type="text" name="input7" placeholder="Input 7" value={formData.input7} onChange={this.handleChange} /><br />
                        <button type="button" onClick={this.nextStep}>Next</button>
                    </form>
                    </div>
                )}

                {step === 2 && (
                    <div>
                    <h3>Step 2</h3>
                    <form>
                        <input type="text" name="input8" placeholder="Input 8" value={formData.input8} onChange={this.handleChange} /><br />
                        <input type="text" name="input9" placeholder="Input 9" value={formData.input9} onChange={this.handleChange} /><br />
                        <input type="text" name="input10" placeholder="Input 10" value={formData.input10} onChange={this.handleChange} /><br />
                        <input type="text" name="input11" placeholder="Input 11" value={formData.input11} onChange={this.handleChange} /><br />
                        <input type="text" name="input12" placeholder="Input 12" value={formData.input12} onChange={this.handleChange} /><br />
                        <input type="text" name="input13" placeholder="Input 13" value={formData.input13} onChange={this.handleChange} /><br />
                        <input type="text" name="input14" placeholder="Input 14" value={formData.input14} onChange={this.handleChange} /><br />
                        <button type="button" onClick={this.prevStep}>Back</button>
                        <button type="button" onClick={this.handleSubmit}>Submit</button>
                    </form>
                    </div>
                )}

                {step === 3 && (
                    <div>
                    <h3>Step 3: Review Your Data</h3>
                    <ul>
                        {Object.entries(formData).map(([key, value]) => (
                        <li key={key}><strong>{key}:</strong> {value}</li>
                        ))}
                    </ul>
                    </div>
                )}
                </div>
            </div>
          </div>
          </GenericSection>              
  
  
        
  
          <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
                <AccordionItem title="What is the BRRRR Calculator?">
                The BRRRR Calculator helps you estimate how profitable it will be to buy a property, rehab it, rent it out, refinance, and repeat the process. It calculates key factors like acquisition costs, rehab costs, rental income, refinancing options, and potential cash flow.
                </AccordionItem>
                <AccordionItem title="How do I calculate rehab costs?">
                Rehab costs include the expenses required to fix up the property to make it rentable. You’ll input the total cost for repairs, upgrades, and renovations. The calculator helps you factor these costs into your overall investment return.
                </AccordionItem>
                <AccordionItem title="What does the refinancing step involve?">
                Refinancing in the BRRRR method allows you to access the increased equity in the property after it has appreciated or been rehabbed. The calculator will help you estimate the amount you can borrow after refinancing, based on the new property value and loan terms.
                </AccordionItem>
                <AccordionItem title="How does rental income affect the BRRRR strategy?">
                Rental income is a key factor in determining your monthly cash flow. The calculator helps you assess how much rent you can expect, accounting for rental rates in your area, and shows how it impacts the overall profitability of your investment.
                </AccordionItem>
                <AccordionItem title="How do I account for occupancy rate in the BRRRR strategy?">
                Occupancy rate reflects the percentage of time the property is rented out. In the BRRRR process, a high occupancy rate ensures consistent rental income. The calculator allows you to adjust the occupancy rate to assess its impact on your cash flow and profitability.
                </AccordionItem>
                <AccordionItem title="How do I calculate cash-out refinancing?">
                Cash-out refinancing lets you take out a new loan for more than your remaining mortgage, giving you access to extra funds. The BRRRR Calculator helps estimate how much cash you can withdraw from the increased property value after refinancing, and how this affects your next investment.
                </AccordionItem>
                <AccordionItem title="How does the BRRRR Calculator help with long-term strategy?">
                The BRRRR Calculator assists in evaluating multiple investment cycles by showing your returns after each stage. By estimating cash flow, equity, and refinancing potential, it helps you plan for future investments and build wealth over time using the BRRRR method.
                </AccordionItem>
            </Accordion>
            </div>
          </GenericSection>         
        </React.Fragment>
      );
    }
  }
  
  // inline style
  const formStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  const modalFormStyle = {
    maxWidth: '320px',
    margin: '0 auto'
  }
  
  export default BRRRRCalcSection;