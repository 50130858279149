import React from 'react';
import AppraisalEstimatorSection from '../components/sections/appraisalEstimator';
import RehabCostCalcFAQ from '../components/sections/faq/rehabCostCalcFAQ'
import Cta from '../components/sections/Cta';

class AppraisalEstimator extends React.Component {
  render() {
    return (
      <React.Fragment>
        <AppraisalEstimatorSection hasBgColor invertColor />
        <RehabCostCalcFAQ hasBgColor invertColor />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default AppraisalEstimator;