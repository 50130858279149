import React from 'react';
import { SignUpForm } from '../components/sections/SignupForm';

class Signup extends React.Component {
  render() {
    return (
      <SignUpForm className="illustration-section-01" />
    );
  }
}

export default Signup;