import React from 'react';
import RentPropCalcSection from '../components/sections/rentpropcalc';
import Cta from '../components/sections/Cta';

class RentPropCalc extends React.Component {
  render() {
    return (
      <React.Fragment>
        <RentPropCalcSection hasBgColor invertColor />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default RentPropCalc;