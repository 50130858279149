import React from 'react';
import BRRRRCalcSection from '../components/sections/brrrrCalc';
import Cta from '../components/sections/Cta';

class BRRRRCalc extends React.Component {
  render() {
    return (
      <React.Fragment>
        <BRRRRCalcSection hasBgColor invertColor />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default BRRRRCalc;